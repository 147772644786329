<template>
  <q-page padding>
    <q-card flat bordered>
      <q-card-section>
        <div class="text-h6">角色权限管理</div>
      </q-card-section>
      <q-separator/>
      <q-card-section>
        <x-responsive-table
          ref="table"
          url="/sysRole/sysRolePage"
          v-model:param="param"
          :paramProps="paramProps"
          :columns="columns"
          row-key="id"
          title-prop="roleName"
        >
          <template v-slot:search>
            <q-input v-model="param.roleName" label="角色名称" placeholder="请输入角色名称" clearable dense outlined/>
            <q-select v-model="param.enable" label="是否启用" :options="$dict.enable" map-options emit-value clearable dense outlined/>
          </template>
          <template v-slot:top-opes>
            <q-btn-group v-if="$hasAuth('role-add')" outline>
              <q-btn label="添加角色" icon="add" color="primary" outline @click="toAdd"/>
            </q-btn-group>
          </template>
          <template v-slot:opes="{row}">
            <q-btn icon="more_vert" color="grey-7" round flat>
              <q-menu cover auto-close>
                <q-list>
                  <q-item v-if="$hasAuth('role-detail')" clickable @click="toDetail(row.id)">
                    <q-item-section class="text-primary">详情</q-item-section>
                  </q-item>
                  <q-item v-if="$hasAuth('role-edit')" clickable @click="toEdit(row.id)">
                    <q-item-section class="text-primary">编辑</q-item-section>
                  </q-item>
                  <q-item v-if="$hasAuth('role-auth-set')" clickable @click="toAuth(row.id)">
                    <q-item-section class="text-primary">授权</q-item-section>
                  </q-item>
                  <q-item v-if="$hasAuth('role-delete')" clickable @click="toDelete(row.id)">
                    <q-item-section class="text-negative">删除</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </template>
        </x-responsive-table>
      </q-card-section>
    </q-card>
  </q-page>
  <teleport to="body">
    <q-dialog v-model="showEditDialog" persistent>
      <q-card style="width:400px;max-width:90vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">角色信息</div>
          <q-space/>
          <q-btn icon="close" flat round dense v-close-popup/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="scroll" style="max-height:60vh">
          <sys-role-form ref="formEle" :data="form" :readonly="readonly"/>
        </q-card-section>
        <template v-if="!readonly">
          <q-separator/>
          <q-card-section class="text-right">
            <q-btn color="primary" label="保存" unelevated @click="save"/>
          </q-card-section>
        </template>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showAuthDialog" persistent>
      <q-card style="width:90vw;max-width:400px;">
        <q-card-section class="row items-center">
          <div class="text-h6">角色授权</div>
          <q-space/>
          <q-btn icon="close" flat round dense v-close-popup/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="scroll" style="max-height:60vh">
          <sys-role-auth-tree ref="authEle" :data="form"/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="text-right">
          <q-btn color="primary" label="确认" unelevated @click="setAuth"/>
        </q-card-section>
      </q-card>
    </q-dialog>
  </teleport>
</template>

<script>
import { getCurrentInstance, ref } from 'vue'
import { XResponsiveTable } from '../../../components/responsive-table/index'
import SysRoleForm from './SysRoleForm'
import SysRoleAuthTree from './SysRoleAuthTree'

export default {
  components: {
    XResponsiveTable,
    SysRoleForm,
    SysRoleAuthTree
  },
  setup () {
    const { proxy } = getCurrentInstance()
    const columns = ref([
      { label: 'ID', name: 'id', field: 'id', align: 'left', sortable: true },
      { label: '角色名称', name: 'roleName', field: 'roleName', align: 'left', sortable: true },
      { label: '排序号', name: 'sortNum', field: 'sortNum', align: 'left', sortable: true },
      { label: '是否启用', name: 'enable', field: 'enable', align: 'left', format: proxy.$dictFormatter('enable'), sortable: true },
      { label: '创建时间', name: 'createTime', field: 'createTime', align: 'left', sortable: true },
      { label: '修改时间', name: 'updateTime', field: 'updateTime', align: 'left', sortable: true }
    ])
    const param = ref({
      roleName: null,
      enable: null,
      exclude: ['remark', 'authorizations']
    })
    const paramProps = ref({
      enable: { type: Boolean }
    })
    const form = ref(null)
    const readonly = ref(false)
    const showEditDialog = ref(false)
    const showAuthDialog = ref(false)

    async function search () {
      await proxy.$refs.table.search()
    }

    async function refresh () {
      await proxy.$refs.table.refresh()
    }

    function toAdd () {
      form.value = null
      readonly.value = false
      showEditDialog.value = true
    }

    async function toDetail (id) {
      form.value = await proxy.$server.post('sysRole/getSysRoleDetail', { id })
      readonly.value = true
      showEditDialog.value = true
    }

    async function toEdit (id) {
      form.value = await proxy.$server.post('sysRole/getSysRoleDetail', { id })
      readonly.value = false
      showEditDialog.value = true
    }

    async function toDelete (id) {
      await proxy.$message.confirm('确定要删除该项吗？')
      await proxy.$server.post('/sysRole/deleteSysRole', { id })
      proxy.$message.tipsSuccess('删除成功')
      proxy.$refs.table.refresh()
    }

    async function save () {
      const param = await proxy.$refs.formEle.validateAndGetForm()
      if (param.id) {
        await proxy.$server.post('/sysRole/updateSysRole', param)
      } else {
        await proxy.$server.post('/sysRole/createSysRole', param)
      }
      proxy.$message.tipsSuccess('保存成功')
      showEditDialog.value = false
      refresh()
    }

    async function toAuth (id) {
      form.value = await proxy.$server.post('sysRole/getSysRoleDetail', { id })
      showAuthDialog.value = true
    }

    async function setAuth () {
      const roleId = form.value.id
      const authorizations = proxy.$refs.authEle.getAuthorizations()
      await proxy.$server.post('/sysRole/setSysRoleAuth', { roleId, authorizations })
      proxy.$message.tipsSuccess('设置成功')
      showAuthDialog.value = false
      refresh()
    }

    return {
      columns,
      param,
      paramProps,
      form,
      readonly,
      showEditDialog,
      showAuthDialog,
      search,
      toAdd,
      toDetail,
      toEdit,
      toDelete,
      toAuth,
      save,
      setAuth
    }
  }
}
</script>
