<template>
  <q-input
    v-model="filterText"
    placeholder="搜索功能"
    dense
    clearable
    outlined
  >
    <template v-slot:append>
      <q-icon name="search"/>
    </template>
  </q-input>
  <x-tree
    class="tree q-mt-md"
    ref="treeEle"
    :nodes="nodes"
    v-model:ticked="ticked"
    v-model:expanded="expanded"
    :filter="filterText"
    node-key="id"
    label-key="funcName"
    children-key="children"
    tick-strategy="leaf-filtered"
  />
</template>

<script>
import { getCurrentInstance, ref } from 'vue'
import { listToNodes } from '../../../utils/common-utils'
import { XTree } from '../../../components/tree'

export default {
  components: {
    XTree
  },
  props: {
    data: { type: Object }
  },
  setup (props) {
    const { proxy } = getCurrentInstance()
    const nodes = ref([])
    const ticked = ref([])
    const expanded = ref([])
    const filterText = ref('')

    async function loadNodes () {
      const list = await proxy.$server.post('/sysFunction/sysFunctionList')
      const data = listToNodes(list, 'id', 'pid', 'children')
      nodes.value = data
      expanded.value = data.map((v) => v.id)
    }

    function loadTicked () {
      const authorizations = props.data.authorizations
      ticked.value = authorizations.map((v) => v.funcId)
    }

    function getAuthorizations () {
      return ticked.value.map((v) => { return { funcId: v } })
    }

    loadNodes()
    loadTicked()

    return {
      nodes,
      ticked,
      expanded,
      filterText,
      getAuthorizations
    }
  }
}
</script>
