<template>
  <q-form ref="formEle" class="q-gutter-md">
    <template v-if="readonly">
      <q-field
        v-if="form.id"
        label="ID"
        :readonly="readonly"
        stack-label
        dense
        outlined
      >
        <span>{{form.id}}</span>
      </q-field>
    </template>
    <q-input
      v-model="form.roleName"
      :rules="rules.roleName"
      label="角色名称"
      :readonly="readonly"
      maxlength="64"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>角色名称</span>
      </template>
    </q-input>
    <q-input
      v-model="form.sortNum"
      :rules="rules.sortNum"
      label="排序号"
      :readonly="readonly"
      dense
      clearable
      outlined
      hide-bottom-space
    />
    <q-field
      v-model="form.enable"
      :rules="rules.enable"
      label="是否启用"
      :readonly="readonly"
      dense
      stack-label
      clearable
      outlined
      hide-bottom-space
    >
      <q-option-group
        v-model="form.enable"
        :options="$dict.enable"
        :disable="readonly"
        inline
      />
    </q-field>
    <q-input
      v-model="form.remark"
      label="备注"
      type="textarea"
      :readonly="readonly"
      maxlength="255"
      dense
      clearable
      outlined
      hide-bottom-space
    />
    <template v-if="readonly">
      <q-field
        v-if="form.createTime"
        label="创建时间"
        :readonly="readonly"
        stack-label
        dense
        outlined
      >
        <span>{{form.createTime}}</span>
      </q-field>
      <q-field
        v-if="form.updateTime"
        label="更新时间"
        :readonly="readonly"
        stack-label
        dense
        outlined
      >
        <span>{{form.updateTime}}</span>
      </q-field>
    </template>
  </q-form>
</template>

<script>
import { getCurrentInstance, nextTick, ref, watch } from 'vue'
import { qNotBlank, qNumber } from '../../../utils/validate-utils'

export default {
  props: {
    data: { type: Object },
    readonly: { type: Boolean, default: false }
  },
  setup (props) {
    const { proxy } = getCurrentInstance()
    const form = ref(dataToForm(props.data))
    const rules = ref({
      roleName: [qNotBlank],
      sortNum: [qNumber]
    })

    function dataToForm (data) {
      return Object.assign({
        roleName: null,
        sortNum: null,
        enable: true,
        remark: null
      }, data)
    }

    async function validate () {
      return await proxy.$refs.formEle.validate()
    }

    async function resetValidation () {
      nextTick(() => proxy.$refs.formEle.resetValidation())
    }

    function getForm () {
      return Object.assign({}, form.value)
    }

    async function validateAndGetForm () {
      if (await validate()) {
        return getForm()
      }
      throw new Error('Form valid error')
    }

    watch(() => props.data, (value) => {
      form.value = dataToForm(value)
      resetValidation()
    })

    return {
      form,
      rules,
      validate,
      resetValidation,
      getForm,
      validateAndGetForm
    }
  }
}
</script>
